<template>
    <form @submit.prevent="submitForm">
        <div v-if="showError" class="error-msg">
            <p>{{ errorMsg }}</p>
        </div>
        <div v-if="showSucces" class="succes-msg">
            <p>{{ succesMsg }}</p>
        </div>
        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-current-password">Current password</label>
                <div class="input-wrapper">
                    <input id="user-current-password" name="user-current-password" type="password" placeholder="Password"
                        v-model="currentPassword" @keyup="changeInput()" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-new-password">New password</label>
                <div class="input-wrapper">
                    <input id="user-new-password" name="user-new-password" type="password" placeholder="Password"
                        v-model="newPassword" @keyup="changeInput()" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-new-password-confirm">Confirm password</label>
                <div class="input-wrapper">
                    <input id="user-new-password-confirm" name="user-new-password-confirm" type="password"
                        placeholder="Password" v-model="newPasswordAgain" @keyup="changeInput()" />
                </div>
            </span>
        </div>

        <button data-txt="submit" class="button">Submit</button>
    </form>
</template>

<script>
import { getUserToken, updatePassword } from "@streampac.io/chef_sp_1";

export default {
    inject: ["isAuthenticated", "setAuthenticated"],
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            newPasswordAgain: "",
            savedToken: "",
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",
        };
    },
    created() {
        if (this.isAuthenticated) {
            getUserToken((token) => {
                this.savedToken = token;
            });
        }
    },
    methods: {
        submitForm() {
            if (
                this.newPassword === this.newPasswordAgain &&
                this.newPassword !== ""
            ) {
                // const postObj = {
                // 	oldPassword: this.currentPassword,
                // 	newPassword: this.newPassword
                // };
                const postObj =
                    "?oldPassword=" +
                    encodeURIComponent(this.currentPassword) +
                    "&newPassword=" +
                    encodeURIComponent(this.newPassword);

                updatePassword(this.isAuthenticated, postObj).then(
                    (response) => {
                        if (response.succes) {
                            if (response.resultcode === 2001) {
                                this.showError = true;
                                this.showSucces = false;
                                this.errorMsg =
                                    "Your current password is not correct";
                            } else if (response.resultcode === 2002) {
                                this.showError = true;
                                this.showSucces = false;
                                this.errorMsg =
                                    "We couldn't save your password";
                            } else if (response.resultCode === 1000) {
                                this.showSucces = true;
                                this.showError = false;
                                this.succesMsg =
                                    "Your password has been changed!";

                                setTimeout(() => {
                                    // clearData();
                                    // this.setAuthenticated(false);
                                    // this.$store.commit('storeRemoveAuthenticatedStatus');
                                    // this.$router.replace({ name: 'Home' });
                                    this.showSucces = true;
                                }, 3000);
                            }
                        } else {
                            this.showError = true;
                            this.errorMsg = "Something went wrong";
                        }
                    }
                );
            } else {
                this.showError = true;
                this.errorMsg = "Your passwords do not match";
            }
        },
        changeInput() {
            this.showError = false;
            this.errorMsg = "";
            this.showSucces = false;
            this.succesMsg = "";
        },
    },
};
</script>
