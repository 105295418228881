<template>
	<form @submit.prevent="submitForm" autocomplete="off">
		<input type="hidden" autocomplete="false" />
		<div v-if="showError" class="error-msg">
			<p>{{ errorMsg }}</p>
		</div>
		<div v-if="showSucces" class="succes-msg">
			<p>{{ succesMsg }}</p>
		</div>
		<div class="form-row">
			<span class="field-wrapper text">
				<label class="top-level" for="user-first-name"
					>First name</label
				>
				<div class="input-wrapper">
					<input
						id="user-first-name"
						name="user-first-name"
						type="text"
						placeholder="First name"
						v-model="userFirstName"
						@keyup="changeInput()"
						autocomplete="off"
					/>
				</div>
			</span>
		</div>

		<div class="form-row">
			<span class="field-wrapper text">
				<label class="top-level" for="user-last-name">Last name</label>
				<div class="input-wrapper">
					<input
						id="user-last-name"
						name="user-last-name"
						type="text"
						placeholder="Last name"
						v-model="userLastName"
						@keyup="changeInput()"
						autocomplete="off"
					/>
				</div>
			</span>
		</div>

		<div class="form-footer">
			<button class="button" @click="saveUserInfo" data-txt="Submit">
				Submit
			</button>
		</div>
	</form>
</template>

<script>
//import { user_profile_update_endpoint } from '@/constants/endpoints';

// import {
// 	activateAccount,
// 	getTempToken,
// 	getUserToken
// } from '@streampac.io/chef_sp_1';

import {
	getUserToken,
	getUserProfile,
	updateUserProfileDetails,
} from "@streampac.io/chef_sp_1";

export default {
	inject: ["isAuthenticated"],
	emits: ["submitted"],
	data() {
		return {
			userFirstName: "",
			userLastName: "",
			savedToken: "",
			userId: "",
			showError: false,
			errorMsg: "",
			showSucces: false,
			succesMsg: "",
		};
	},
	created() {
		if (this.isAuthenticated) {
			getUserToken((token) => {
				this.savedToken = token;
				this.fetchUserInfo(token);
			});
		}
	},
	methods: {
		showPasswordPopup() {
			this.$store.commit("showChangePasswordPopup");
		},
		showChangeUsernamePopup() {
			this.$store.commit("showChangeUsernamePopup");
		},
		changeInput() {
			this.showError = false;
			this.errorMsg = "";
			this.showSucces = false;
			this.succesMsg = "";
		},
		fetchUserInfo(token) {
			getUserProfile(token).then((response) => {
				this.userId = response.userId;
			});
		},
		saveUserInfo() {
			const fieldsObj = {
				firstName: this.userFirstName,
				lastName: this.userLastName,
			};

			const userId = this.userId;

			updateUserProfileDetails(this.savedToken, userId, fieldsObj).then(
				() => {
					this.showSucces = true;
					this.succesMsg = "Your edit has been saved!";
					setTimeout(() => {
						this.$emit("submitted", true);
					}, 1500);
				}
			);
		},
	},
};
</script>
