<template>
    <form @submit.prevent="submitForm" autocomplete="off">
        <input type="hidden" autocomplete="false" />
        <div v-if="showError" class="error-msg">
            <p>{{ errorMsg }}</p>
        </div>
        <div v-if="showSucces" class="succes-msg">
            <p>{{ succesMsg }}</p>
        </div>
        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-current-email">Current e-mail</label>
                <div class="input-wrapper">
                    <input id="user-current-email" name="user-current-email" type="email" placeholder="E-mail"
                        v-model="currentUsername" @keyup="changeInput()" autocomplete="off" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-new-email">New e-mail</label>
                <div class="input-wrapper">
                    <input id="user-new-email" name="user-new-email" type="email" placeholder="E-mail" v-model="newUsername"
                        @keyup="changeInput()" autocomplete="off" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-new-email-confirm">Confirm E-mail</label>
                <div class="input-wrapper">
                    <input id="user-new-email-confirm" name="user-new-email-confirm" type="email" placeholder="E-mail"
                        v-model="newUsernameAgain" @keyup="changeInput()" autocomplete="off" />
                </div>
            </span>
        </div>

        <button data-txt="submit" class="button">Submit</button>
    </form>
</template>

<script>
import {
    getUserToken,
    updateUserName,
    getUserInfo,
    clearData,
} from "@streampac.io/chef_sp_1";

export default {
    inject: ["isAuthenticated", "setAuthenticated"],
    data() {
        return {
            currentUsername: "",
            currentUsernameSaved: "",
            newUsername: "",
            newUsernameAgain: "",
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",
        };
    },
    created() {
        if (this.isAuthenticated) {
            getUserToken((token) => {
                getUserInfo(token).then((response) => {
                    this.currentUsernameSaved = response.userName;
                });
            });
        }
    },
    methods: {
        submitForm() {
            if (
                this.currentUsername !== this.currentUsernameSaved &&
                this.currentUsername !== ""
            ) {
                this.showError = true;
                this.showSucces = false;
                this.errorMsg = "Your current e-mail is not correct";
            } else if (
                this.newUsername !== this.newUsernameAgain ||
                this.newUsername == "" ||
                this.newUsernameAgain == ""
            ) {
                this.showError = true;
                this.showSucces = false;
                this.errorMsg = "Your e-mail's do not match";
            } else {
                const postObj = "?username=" + encodeURIComponent(this.newUsername);
                updateUserName(this.isAuthenticated, postObj).then(
                    (response) => {
                        if (response.succes) {
                            if (response.resultcode === 2001) {
                                this.showError = true;
                                this.showSucces = false;
                                this.errorMsg = "Something went wrong..";
                            } else if (response.resultCode === 1000) {
                                this.showSucces = true;
                                this.showError = false;
                                this.succesMsg =
                                    "Your e-mail has been changed!";

                                //Remove the popup
                                setTimeout(() => {
                                    this.$store.commit(
                                        "storeRemoveAuthenticatedStatus"
                                    );
                                }, 1500);

                                //Logout
                                setTimeout(() => {
                                    this.$store.commit(
                                        "hideChangeUsernamePopup"
                                    );
                                    clearData();
                                    this.setAuthenticated(false);
                                    this.$router.replace({ name: "Home" });
                                }, 3000);
                            }
                        } else {
                            this.showError = true;
                            this.showSucces = false;
                            this.errorMsg = "Something went wrong..";
                        }
                    }
                );
            }
        },
        changeInput() {
            this.showError = false;
            this.errorMsg = "";
            this.showSucces = false;
            this.succesMsg = "";
        },
    },
};
</script>
