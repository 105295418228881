<template>
    <HeroPage v-if="pageData" :data="pageData" heroType="center" />

    <section class="account-overview">
        <div class="container">
            <div class="column-wrapper">
                <div class="column nav">
                    <nav>
                        <ul>
                            <li>
                                <router-link to="/account">
                                    Settings
                                </router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="column account-info">
                    <div class="row account-details">
                        <div class="title-wrapper">
                            <h2 class="column-title">Account settings</h2>
                        </div>
                        <!-- Voornaam + achternaam -->
                        <div class="inner-row">
                            <div class="left-side">
                                <span class="key">Name</span>

                                <span v-if="currentUser != null" class="value">
                                    {{ currentUser.firstName }}
                                    {{ currentUser.lastName }}
                                </span>
                            </div>
                            <div class="right-side">
                                <button @click="editUserDetails">Edit</button>
                            </div>
                        </div>
                        <!-- Email -->
                        <div class="inner-row">
                            <div class="left-side">
                                <span class="key">E-mail</span>
                                <span class="value" v-if="currentUser != null">{{ currentUser.userName }}</span>
                            </div>
                            <div class="right-side">
                                <button @click="editUsername">Edit</button>
                            </div>
                        </div>
                        <!-- Wachtwoord -->
                        <div class="inner-row">
                            <div class="left-side">
                                <span class="key">Password</span>
                                <span class="value">******</span>
                            </div>
                            <div class="right-side">
                                <button @click="editPassword">
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row sub-details">
                        <div class="title-wrapper">
                            <h2 class="column-title">Subscription</h2>
                        </div>
                        <div class="inner-row">
                            <div class="left-side">
                                <span class="key" v-if="subscriptionInformation &&
                                    subscriptionInformation.name !==
                                    '' &&
                                    subscriptionInformation.name !==
                                    'Free'
                                    ">{{
        getCorrectSubscriptionName(
            subscriptionInformation
        )
    }}</span>
                                <span class="value" v-else-if="(subscriptionInformation &&
                                            subscriptionInformation.name ==
                                            '') ||
                                        (subscriptionInformation &&
                                            subscriptionInformation.name ==
                                            'Free')
                                        ">No subscription active</span>
                                <span class="value" v-if="subscriptionInformation &&
                                    subscriptionInformation.validUntill &&
                                    subscriptionInformation &&
                                    subscriptionInformation.name !==
                                    '' &&
                                    subscriptionInformation.name !==
                                    'Free'
                                    ">Active untill:
                                    {{
                                        subscriptionInformation.validUntill
                                    }}</span>
                            </div>
                            <div class="right-side">
                                <!-- <router-link to="/pricing">Change subscription</router-link> -->
                                <!-- <button @click="changeSubscription()">
									Change subscription
								</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <transition name="fade">
        <Popup :elemVisible="showUserDetailsPopup" :elemClass="'change-user-details'" @hidePopupEl="hidePopups()">
            <div class="column form">
                <div class="title-wrapper">
                    <h2 class="column-title">Edit details</h2>
                </div>
                <UserProfile @submitted="
                    hidePopups();
                getAllAccountData();
                " />
                <button class="close-btn" @click="hidePopups()">
                    X
                </button>
            </div>
        </Popup>
    </transition>

    <transition name="fade">
        <Popup :elemVisible="showPasswordPopup" :elemClass="'change-pw'" @hidePopupEl="hidePopups()">
            <div class="column form">
                <div class="title-wrapper">
                    <h2 class="column-title">Change password</h2>
                </div>
                <ChangePasswordForm />
                <button class="close-btn" @click="hidePopups()">
                    X
                </button>
            </div>
        </Popup>
    </transition>

    <transition name="fade">
        <Popup :elemVisible="showUsernamePopup" :elemClass="'change-username'" @hidePopupEl="hidePopups()">
            <div class="column form">
                <div class="title-wrapper">
                    <h2 class="column-title">Change e-mail</h2>
                </div>
                <ChangeUsernameForm />
                <button class="close-btn" @click="hidePopups()">
                    X
                </button>
            </div>
        </Popup>
    </transition>

    <transition name="fade">
        <Popup :elemVisible="showChangeSubscriptionPopup" elemClass="change-subscription" @hidePopupEl="hidePopups()">
            <div class="column form">
                <div class="title-wrapper">
                    <h2 class="column-title">Your subscription</h2>
                    <span class="column-subtitle">Mange your subscription here</span>
                </div>

                <div class="current-sub-info">
                    <strong class="sub">
                        Your subscription:
                    </strong>

                    <span class="sub-name" v-if="subscriptionInformation &&
                        subscriptionInformation.name !== ''
                        ">{{
        getCorrectSubscriptionName(subscriptionInformation)
    }}</span>
                </div>

                <form @submit.prevent="changeSubscriptionApiCall()">
                    <div class="form-row">
                        <span class="field-wrapper radio" v-if="getAllPriceplans && getAllPriceplans.length > 0
                            ">
                            <!-- <label class="top-level" for="account-choose-priceplan"
								>Make a choice</label
							> -->
                            <div class="input-wrapper" v-for="(item, index) in getAllPriceplans" :key="item.pricePlanId">
                                <input :id="'id-radio-field-' + index" name="account-choose-priceplan" type="radio"
                                    :value="getCorrectPriceplanVal(item)" v-model="chosenSubscription" />
                                <label :for="'id-radio-field-' + index">{{ item.displayTitle }} -
                                    {{ item.price }}</label>
                            </div>
                        </span>
                    </div>
                    <div class="form-row remove-whitespace">
                        <button type="button" class="cancel-btn">
                            <!-- <button
							type="button"
							class="cancel-btn"
							@click="cancelSubscriptionStep1()"
						> -->
                            Cancel subscription
                        </button>
                    </div>

                    <div class="sub-until" v-if="subscriptionInformation &&
                        subscriptionInformation.validUntill
                        ">
                        *If you cancel now, you still have access to your
                        subscription until
                        {{ subscriptionInformation.validUntill }}
                    </div>

                    <button class="submit" data-txt="Confirm">Confirm</button>
                </form>

                <button class="close-btn" @click="hidePopups()">
                    X
                </button>

                <transition name="fade">
                    <Popup :elemVisible="showCancelSubConfirm" elemClass="confirmation-box" @hidePopupEl="hideConfirms()">
                        <div class="column content">
                            <h2 class="column-title">Are you sure?</h2>
                            <div class="inner-wrapper">
                                <ul>
                                    <li>
                                        <button type="button" class="button" data-txt="No" @click="hideConfirms()">
                                            No
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="regular-txt" @click="cancelSubscriptionStep2()">
                                            Yes
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Popup>
                </transition>
                <transition name="fade">
                    <Popup :elemVisible="showCancelAccountConfirmStep1" elemClass="confirmation-box"
                        @hidePopupEl="hideConfirms()">
                        <div class="column content">
                            <h2 class="column-title">
                                Do you also want to cancel your account?
                            </h2>
                            <div class="inner-wrapper">
                                <ul>
                                    <li>
                                        <button type="button" class="button" data-txt="No" @click="hideConfirms()">
                                            No
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="regular-txt" @click="cancelAccountStep1()">
                                            Yes
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Popup>
                </transition>
                <transition name="fade">
                    <Popup :elemVisible="showCancelAccountConfirmStep2" elemClass="confirmation-box"
                        @hidePopupEl="hideConfirms()">
                        <div class="column content">
                            <h2 class="column-title">
                                Are you sure?
                            </h2>
                            <div class="inner-wrapper">
                                <ul>
                                    <li>
                                        <button type="button" class="button" data-txt="No" @click="hideConfirms()">
                                            No
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="regular-txt" @click="cancelAccountStep2()">
                                            Yes
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Popup>
                </transition>
                <transition name="fade">
                    <Popup :elemVisible="showFinalCancelAccountMessage" elemClass="confirmation-box"
                        @hidePopupEl="hideConfirms()">
                        <div class="column content">
                            <h2 class="column-title">
                                Your account has been canceled
                            </h2>
                            <div class="inner-wrapper"></div>
                        </div>
                    </Popup>
                </transition>
            </div>
        </Popup>
    </transition>
</template>

<script>
import {
    //cms,
    getOrders,
    fetchMediaList,
    getUserToken,
    getUserInfo,
    getUserProfile,
    fetchPricePlanList,
    checkoutSubscription,
} from "@streampac.io/chef_sp_1";

import { current_enviroment } from "@/constants/endpoints";

import Popup from "@/components/Popup";

// // @import constants
// import { current_enviroment } from "@/constants/endpoints";

// @import component
import ChangePasswordForm from "@/components/Forms/ChangePasswordForm";
import ChangeUsernameForm from "@/components/Forms/ChangeUsernameForm";
import UserProfile from "@/components/Forms/UserProfile";

//@import component
import HeroPage from "@/components/HeroPage";

export default {
    name: "Account",
    inject: ["isAuthenticated"],
    components: {
        ChangePasswordForm,
        ChangeUsernameForm,
        Popup,
        UserProfile,
        HeroPage,
    },
    data() {
        return {
            getSubscriptions: [],
            getPayPerView: [],
            getMediaIds: null,
            mediaItemObj: null,
            subscriptionInformation: null,
            currentUser: null,
            currentUserProfile: null,
            showPasswordPopup: false,
            showUsernamePopup: false,
            showUserDetailsPopup: false,
            showChangeSubscriptionPopup: false,
            chosenSubscription: null,
            showCancelSubConfirm: false,
            getAllPriceplans: null,
            showCancelAccountConfirmStep1: false,
            showCancelAccountConfirmStep2: false,
            showFinalCancelAccountMessage: false,
            pageData: {
                headerTitle: 'Settings',
                //headerSubtitle: 'subtitle'
            }
        };
    },
    created() {
        this.getAllAccountData();
        this.fetchAllPricePlans();
    },
    methods: {
        getOrderData() {
            getOrders(this.isAuthenticated).then((response) => {
                //Return unique items
                const unique = (value, index, self) => {
                    return self.indexOf(value) === index;
                };

                const returnSubscriptionsObj = response.filter(
                    (item) =>
                        item.pricePlan != null &&
                        item.status == "succeeded" &&
                        Date.parse(item.payedUntill) > Date.now()
                );

                const payPerViewObj = response.filter(
                    (item) =>
                        item.pricePlan == null &&
                        item.status == "succeeded" &&
                        Date.parse(item.payedUntill) > Date.now()
                );

                this.getSubscriptions = returnSubscriptionsObj;
                this.getPayPerView = payPerViewObj;

                let tempMediaArray = [];

                //Filter for unique items
                this.getPayPerView.forEach((element) =>
                    tempMediaArray.push(element.mediaId)
                );

                this.getMediaIds = tempMediaArray.filter(unique);
            });

            fetchMediaList(this.isAuthenticated, "default").then((response) => {
                if (this.getMediaIds && this.getMediaIds.length > 0) {
                    const mediaItems = response.filter((item) =>
                        this.getMediaIds.includes(item.mediaId)
                    );
                    this.mediaItemObj = mediaItems;
                }
            });
        },
        getProductDetails(mediaId) {
            if (this.mediaItemObj != null) {
                const mediaObj = this.mediaItemObj.filter(
                    (item) => item.mediaId == mediaId
                );

                let item = Object.assign({}, mediaObj[0]);

                return item.title;
            }
        },
        dateFormatted(date) {
            let initialDate = date;

            //Timezone offset
            let hrs = -(new Date().getTimezoneOffset() / 60);

            const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            let d = new Date(initialDate);

            const addZero = (i) => {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            };

            const monthIndex = d.getMonth();
            const monthName = months[monthIndex];
            const year = d.getFullYear();
            const day = d.getDate();
            const hours = addZero(d.getHours() + hrs);
            const minutes = addZero(d.getMinutes());

            return (
                day +
                "th of " +
                monthName +
                " " +
                year +
                " " +
                hours +
                ":" +
                minutes
            );
        },
        showActiveSubscription() {
            if (this.currentUser) {
                const subInfo = {};

                setTimeout(() => {
                    if (
                        this.currentUser.subscription &&
                        this.currentUser.subscription !== ""
                    ) {
                        subInfo.name = this.currentUser.subscription;

                        if (
                            this.currentUser.subscriptionValidity &&
                            this.currentUser.subscriptionValidity !== ""
                        ) {
                            subInfo.validUntill = this.dateFormatted(
                                this.currentUser.subscriptionValidity
                            );
                        }

                        this.subscriptionInformation = subInfo;
                    }
                }, 300);
            }
        },
        getUserData(token) {
            getUserInfo(token).then((response) => {
                setTimeout(() => {
                    this.currentUser = response;
                }, 50);
            });
            getUserProfile(token).then((response) => {
                this.currentUserProfile = response;
            });
        },
        editPassword() {
            this.showPasswordPopup = !this.showPasswordPopup;
        },
        editUsername() {
            this.showUsernamePopup = !this.showUsernamePopup;
        },
        editUserDetails() {
            this.showUserDetailsPopup = !this.showUserDetailsPopup;
        },
        changeSubscription() {
            this.showChangeSubscriptionPopup = !this
                .showChangeSubscriptionPopup;
        },
        hidePopups() {
            this.showPasswordPopup = false;
            this.showUsernamePopup = false;
            this.showUserDetailsPopup = false;
            this.showChangeSubscriptionPopup = false;
            this.showCancelSubConfirm = false;
        },
        hideConfirms() {
            this.showCancelSubConfirm = false;
            this.showCancelAccountConfirmStep1 = false;
            this.showCancelAccountConfirmStep2 = false;
        },
        getAllAccountData() {
            if (this.isAuthenticated) {
                this.getOrderData();
                getUserToken((token) => {
                    this.getUserData(token);
                    setTimeout(() => {
                        this.showActiveSubscription();
                    }, 500);
                });
            }
        },
        fetchAllPricePlans() {
            fetchPricePlanList(this.isAuthenticated).then((response) => {
                this.getAllPriceplans = response;
            });
        },
        changeSubscriptionApiCall() {
            let postObj = {
                subscriptionPriceId: this.chosenSubscription,
            };

            checkoutSubscription(this.isAuthenticated, postObj).then(
                (response) => {
                    // console.log(response);
                    if (response.succes) {
                        window.location.replace(response.redirectUrl);
                    }
                }
            );
        },
        cancelSubscriptionStep1() {
            //("prompt are you sure?");
            this.showCancelSubConfirm = true;
        },
        cancelSubscriptionStep2() {
            //console.log("Your subscription has been cancelled.");
            this.showCancelAccountConfirmStep1 = true;
        },
        cancelAccountStep1() {
            this.showCancelAccountConfirmStep1 = false;
            this.showCancelAccountConfirmStep2 = true;
            //console.log("show next cancel step");
        },
        cancelAccountStep2() {
            //console.log("Account has been cancelled");
            //this.hideConfirms();

            this.showFinalCancelAccountMessage = true;

            setTimeout(() => {
                this.showFinalCancelAccountMessage = false;

                //Also remove first step
                this.showChangeSubscriptionPopup = false;
            }, 1000);
        },
        getCorrectPriceplanVal(val) {
            let chosenId = "";

            if (
                current_enviroment === "localhost" ||
                current_enviroment === "test"
            ) {
                if (val.stripePriceTestId && val.stripePriceTestId !== "") {
                    chosenId = val.stripePriceTestId;
                }
            } else {
                if (val.stripePriceId && val.stripePriceId !== "") {
                    chosenId = val.stripePriceId;
                }
            }

            return chosenId;
        },
        getCorrectSubscriptionName(val) {
            if (val && val.name !== "" && val.name !== "Free") {
                if (this.getAllPriceplans && this.getAllPriceplans.length > 0) {
                    let filterPriceplans;

                    if (val.name.includes("price_")) {
                        if (
                            current_enviroment === "localhost" ||
                            current_enviroment === "test"
                        ) {
                            if (val.name && val.name !== "") {
                                filterPriceplans = this.getAllPriceplans.filter(
                                    (item) => item.stripePriceTestId == val.name
                                );
                            }
                        } else {
                            if (val.name && val.name !== "") {
                                filterPriceplans = this.getAllPriceplans.filter(
                                    (item) => item.stripePriceId == val.name
                                );
                            }
                        }
                    } else {
                        filterPriceplans = this.getAllPriceplans.filter(
                            (item) => item.pricePlanId == val.name
                        );
                    }

                    if (filterPriceplans && filterPriceplans.length > 0) {
                        return filterPriceplans[0].displayTitle;
                    }
                }
            } else if (val && val.name == "Free") {
                return "Free";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
