<template>
	<section class="global-popup" :class="elemClass" v-if="elemVisible">
		<div class="container">
			<div class="column-wrapper">
				<slot></slot>
			</div>
		</div>

		<div class="background-el" @click="hideElem"></div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			//hideCurrentElem: false
		};
	},
	inject: ["isAuthenticated"],
	props: ["elemClass", "elemVisible"],
	emits: ["hidePopupEl"],
	// components: {
	// 	LoginPopup,
	// 	ForgotPasswordPopup
	// },
	created() {},
	watch: {
		$route() {
			this.hideElem();
		},
	},
	methods: {
		hideElem() {
			this.$emit("hidePopupEl", "false");
		},
	},
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
